<template>
  <div class="page-simplePage-detail">
    <a-card :bordered="false">
      <a-button @click="refreshNextPage">重载并返回前一个页面</a-button>
      <a-button @click="back">仅返回</a-button>
    </a-card>
  </div>
</template>
<script>
export default {
  methods:{
    back(){
      this.$router.go(-1)
    },
    refreshNextPage(){
      this.$routex.reloadNext()
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
</style>
